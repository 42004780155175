<template>
  <v-theme-provider dark>
    <base-section
      id="the-solution"
      class="red px-4"
      space="40"
    >
      <base-section-heading
        title="ATENÇÃO!"
        color="red lighten-2"
        class="white--text"
      />
      <v-container>
        <v-row justify="center">
          <v-col
            cols="12"
            md="10"
            sm="10"
          >
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              title="ATENÇÃO. Esse acompanhamento não é pra você que busca:"
            />

            <base-body space="2">
              <span class="white--text text-justify">
                <ul>
                  <li>Uma fórmula mágica pra mudar seu corpo e saúde num piscar de olhos, o método de <b>Nutrição Moderna</b> é transmite técnicas de alimentação e comportamento aplicadas à qualquer contexto nutricional, já testados com pacientes em diversos países do mundo.</li>
                  <li>Não é um modelo de nutrição feito pra você ter um resultado absurdo em um mês as custas de uma alimentação sofrida, restrita e cara e que geralmente são perdidos logo em seguida devido ao forte efeito rebote que elas causam.</li>
                  <li>A Nutrição Moderna vai ajudar você a erguer os alicerces necessários para gerar e manter seus resultados enquanto tem uma alimentação prática, acessível e saborosa. Não é um plano de 21 dias ou “projeto verão” com conteúdos básicos, óbvios e ineficazes dizendo o que você pode e o que você não pode fazer na sua alimentação e ensinando técnicas que não estão conectadas com a sua realidade.</li>
                </ul>
              </span>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionProductReinforcement',
  }
</script>
